@use 'sass:math';
@import '../../../styles/variables';

.visatSdg-FeatureSelectForm {
  padding: m(1);
  min-width: 30rem;
  overflow: hidden;
}

.visatSdg-FeatureSelectForm h2 {
  margin: 0 0 .5rem;
  color: $textColor_primary;
}

.visatSdg-FeatureSelectForm-content {
  margin-top: m(0.7);
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  max-width: 20rem;
}

.visatSdg-FormItem > label {
  font-size: $b1;
  color: $textColor_primary;
}

.visatSdg-FeatureSelectForm-controlButtons {
	gap: 0.7rem;
	display: flex;
	margin-top: 1rem;
	z-index: 0;
}

.visatSdg-FeatureSelectForm-controlButtons .ptr-button {
	min-width: 6rem;
	text-align: center;
}

.ptr-button.primary.visatSdg-FeatureSelectForm-saveBtn {
	background-color: var(--base60);
}

.ptr-button.primary.visatSdg-FeatureSelectForm-saveBtn.has-changes {
	background-color: var(--accent50);
}