@use 'sass:math';
@import '../../../styles/variables';

.visatSdg-CompareMapsControl.ptr-IconTool {
  background: $backgroundColor_lightTransparent;
  @include frozenGlassEffect();
}

.visatSdg-CompareMapsControl.ptr-IconTool:hover {
  background: var(--base0);
  @include frozenGlassEffect();
}

.ptr-IconTool.visatSdg-CompareMapsControl.is-floating:hover:after {
  background: none;
}