@use 'sass:math';
@import '../../styles/variables';

$mapBorder: 0.05rem solid var(--base30);

.ptr-MapSetContainer {
  height: calc(100% + .1rem);
  margin-top: -.1rem;
}

.ptr-MapSetContainer .ptr-map-set {
  height: 100%;
}

.ptr-map-wrapper.active::after {
  border: 0;
}

.ptr-MapSetContainer .ptr-map-grid .ptr-map-grid-cell.row1 .ptr-map {
  border-top: 0;
}

.ptr-MapSetContainer .ptr-map-grid .ptr-map-grid-cell.col1 .ptr-map {
  border-left: 0;
}

.ptr-MapSetContainer .ptr-map-grid .ptr-map-grid-cell:not(.row1) .ptr-map {
  border-top: $mapBorder;
}

.ptr-MapSetContainer .ptr-map-grid .ptr-map-grid-cell:not(.col1) .ptr-map {
  border-left: $mapBorder;
}

// Map slider
.visatSdg-CompareSlider {
  height: 100%;
  margin-top: -1px;
}

div[data-rcs='handle-container'] {
  z-index: 3;
}

div[data-rcs='clip-item'] {
  z-index: 2;
}

.ptr-map-controls-wrapper {
  z-index: auto;
}

// Map set tools
.visatSdg-MapSetTools {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: bottom $elementOpeningTransitionDuration $elementOpeningTransitionStyle;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
}

.visatSdg-MapSetTools.is-open {
  bottom: $bottomPanelHeight;
}

// MapInfoElements
.visatSdg-MapInfoElements {
  pointer-events: none;
  position: absolute;
  bottom: .15rem;
  right: .8rem;
  display: flex;
  grid-gap: 0.35rem;
  flex-direction: column;
  z-index: 9;
}

// AttributionScaleContainer
.visatSdg-AttributionScaleContainer {
  display: flex;
  grid-gap: 0.35rem;
}

// Map set controls
.visatSdg-MapSetControls {
  position: absolute;
  right: .75rem;
  bottom: 1.85rem;
  grid-gap: 0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 9;
}

// Unsynced maps
.visatSdg-UnsyncedMaps {
  width: 100%;
  height: 100%;
  display: flex;
}

.visatSdg-UnsyncedMaps > div {
  width: 50%;
}

.visatSdg-UnsyncedMaps > div:first-child {
  border-right: $mapBorder;
}

.visatSdg-MapTools {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: bottom $elementOpeningTransitionDuration $elementOpeningTransitionStyle;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
}

.visatSdg-MapTools.is-open {
  bottom: $bottomPanelHeight;
}

.visatSdg-MapTools.is-left-map .visatSdg-MapInfoElements {
  left: .8rem;
  right: auto;
}

.visatSdg-MapTools.is-left-map .visatSdg-AttributionScaleContainer {
  flex-direction: row-reverse;
}

.visatSdg-MapTools.is-left-map .visatSdg-MapSetControls{
  left: .8rem;
  right: auto;
}