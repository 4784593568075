@import '../../../styles/variables';

.ptr-MapAttribution {
	z-index: 2;
	position: relative;
	font-size: $b0;
	color: var(--base50);
	padding: 0 0.35rem;
}

.ptr-MapAttribution a {
	color: var(--base50);
}
