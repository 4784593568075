@use 'sass:math';
@import '../../../styles/variables';

.visatSdg-ProgressChart {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.visatSdg-ProgressChart-tools {
	display: flex;
	margin-right: 0.5rem;
}

.visatSdg-ProgressChart-tools > div:first-child {
	flex: 1;
	display: flex;
	grid-gap: 0.75rem;
}

.ptr-button-group.visatSdg-ProgressChartTypeSelector {
	display: flex;
	grid-gap: 0.25rem;
}

.ptr-button-group.visatSdg-ProgressChartTypeSelector .ptr-button {
	background: none;
	box-shadow: none;
	border: 0;
	color: $textColor_secondary;
	border-radius: 0;
	padding: 0 0.25rem;
	min-height: auto;
}

.ptr-button-group.visatSdg-ProgressChartTypeSelector .ptr-button.active {
	color: $textColor_accented;
	border-bottom: 2px dotted $iconColor_accented;
}

.ptr-button-group.visatSdg-ProgressChartTypeSelector .ptr-button:after {
	border: 0;
	box-shadow: none;
}

.ptr-button-group.visatSdg-ProgressChartTypeSelector .ptr-button:hover,
.ptr-button-group.visatSdg-ProgressChartTypeSelector .ptr-button:focus {
	background: $backgroundColor_lightAccent;
}

.ptr-button-group.visatSdg-ProgressChartTypeSelector .ptr-button .ptr-button-content {
	font-size: $b1;
	padding: 0.15rem 0 0;
}

.ptr-button-group.visatSdg-ProgressChartTypeSelector .ptr-button .ptr-button-content span em {
	font-size: $a0;
}

.ptr-button-group.visatSdg-ProgressChartTypeSelector .ptr-button.active .ptr-button-content,
.ptr-button-group.visatSdg-ProgressChartTypeSelector .ptr-button.active .ptr-button-content span {
	font-weight: bold;
}

.visatSdg-ProgressChart-chart {
	height: calc(100% - 2rem);
	margin-top: 0.5rem;
}

.visatSdg-nivo-tooltip-time-alternative {
	font-size: $a1;
	color: var(--base90);
	margin: 0;
}

.visatSdg-nivo-tooltip-item-alternative {
	font-size: $b1;
	margin-top: 0.25rem;
	color: var(--base90);
	display: flex;
	align-items: center;
	flex-direction: row;
	line-height: 1;
}

.visatSdg-nivo-tooltip-color-alternative {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	margin-right: 0.35rem;
}

.visatSdg-nivo-tooltip {
	background: var(--base0);
	color: inherit;
	font-size: $b1;
	padding: 0;

	.visatSdg-nivo-tooltip-content {
		white-space: pre;
		display: flex;
		flex-flow: column;
		align-items: flex-start;

		h2 {
			font-size: $b2;
			color: var(--base90);
			margin: 0;

			.visatSdg-nivo-tooltip-color {
				display: inline-block;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				margin-right: 0.35rem;
			}
		}

		h4 {
			color: var(--base90);
			margin: 0;

			.visatSdg-nivo-tooltip-color {
				display: inline-block;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				margin-right: 0.35rem;
			}
		}

		> div {
			margin-top: 0.5rem;
			display: flex;
			flex-flow: row;
		}

		span.visatSdg-nivo-tooltip-time {
			color: var(--base50);
			font-size: $b1;
		}

		span.visatSdg-nivo-tooltip-label {
			display: inline-block;
			color: var(--base50);
			font-size: $b1;
			margin-right: 0.25rem;

			&::first-letter {
				text-transform: uppercase;
			}
		}
		span.visatSdg-nivo-tooltip-value {
			color: var(--base90);
			font-weight: bold;
			font-size: $b1;
			margin-right: 0.25rem;
		}
		span.visatSdg-nivo-tooltip-deviation {
			color: var(--base50);
			font-size: $b1;
		}
		span.visatSdg-nivo-tooltip-unit {
			color: var(--base50);
			font-weight: normal;
		}
	}
}
