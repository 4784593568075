@use 'sass:math';
@import '../../styles/variables';

.visatSdg-BottomPanel {
  position: absolute;
  height: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 600;
  transition: height $elementOpeningTransitionDuration $elementOpeningTransitionStyle;
  background: $backgroundColor_lightTransparent;
  @include frozenGlassEffect();
  border-top: 1px solid $borderColor_light;
}

.visatSdg-BottomPanel.is-open {
  height: $bottomPanelHeight;
}

.visatSdg-BottomPanel.is-open.is-expanded {
  height: calc(100%);
  background: $backgroundColor_light;
}

.visatSdg-BottomPanel-content {
  height: $bottomPanelHeight;
  width: 100%;
  overflow: hidden;
}

.visatSdg-BottomPanel.is-open.is-expanded .visatSdg-BottomPanel-contentWrapper {
  margin-top: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.visatSdg-BottomPanel.is-open.is-expanded .visatSdg-BottomPanel-contentWrapper .visatSdg-BottomPanel-content {
  height: 100%;
  max-height: 30rem;
}