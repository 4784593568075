@import '../../../styles/variables';

.visatSdg-MapWrapper {
	position: relative;
}

.visatSdg-MapLabels {
	position: absolute;
	top: calc($mapPadding + 0.1rem);
	left: $mapPadding;
	z-index: 2;
	display: flex;
	flex-direction: column;
	grid-gap: .35rem;
}

.visatSdg-MapWrapper.is-right .visatSdg-MapLabels {
	left: auto;
	right: $mapPadding;
}

.visatSdg-IndicatorsContainer {
	position: absolute;
	left: $mapPadding;
	z-index: 2;
	transition: bottom $elementOpeningTransitionDuration $elementOpeningTransitionStyle;
}

.visatSdg-MapWrapper.is-right .visatSdg-IndicatorsContainer {
	left: auto;
	right: 3.7rem;
}

.visatSdg-MapWrapper.has-left-controls .visatSdg-IndicatorsContainer {
	left: 3.7rem;
}