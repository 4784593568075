@import '../../../styles/variables';

.ptr-Checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: $b1;
}

.ptr-Checkbox.is-disabled {
  pointer-events: none;
  color: $textColor_disabled;
}

.ptr-Checkbox > input {
  accent-color: #11bda3;
  filter: invert(0);
}

.ptr-Checkbox > span {
  margin-left: .25rem;
  color: $textColor_secondary;
}

.ptr-Checkbox.is-disabled > span {
  color: $textColor_disabled;
}