@import '~@gisatcz/ptr-core/lib/styles/_variables';

$lightAccent0: hsl(40, 100%, 99.41%);
$lightAccent0rgb: 255, 254, 252;
$lightAccent05: hsl(160, 47.37%, 96.27%);
$lightAccent10: hsl(164.21, 51.35%, 92.75%);
$lightAccent15: hsl(166.45, 56.36%, 89.22%);
$lightAccent20: hsl(166.83, 56.16%, 85.69%);
$lightAccent25: hsl(168.24, 56.04%, 82.16%);
$lightAccent30: hsl(169.6, 56.39%, 73.92%);
$lightAccent35: hsl(169.8, 56.82%, 65.49%);
$lightAccent40: hsl(170.73, 56.16%, 57.06%);
$lightAccent45: hsl(170.68, 59.68%, 48.63%);
$lightAccent50: hsl(170.93, 83.5%, 40.39%);
$lightAccent50rgb: 17, 189, 163;
$lightAccent55: hsl(170.74, 83.51%, 38.04%);
$lightAccent60: hsl(170.53, 83.52%, 35.69%);
$lightAccent65: hsl(171, 82.35%, 33.33%);
$lightAccent70: hsl(170.77, 82.28%, 30.98%);
$lightAccent75: hsl(170.5, 82.19%, 28.63%);
$lightAccent80: hsl(170, 81.36%, 23.14%);
$lightAccent85: hsl(169.17, 80%, 17.65%);
$lightAccent90: hsl(168.51, 74.6%, 12.35%);
$lightAccent95: hsl(164.35, 65.71%, 6.86%);
$lightAccent100: hsl(45, 100%, 0.78%);
$lightAccent100rgb: 4, 3, 0;

$darkAccent0: hsl(45, 100%, 0.78%);
$darkAccent0rgb: 4, 3, 0;
$darkAccent05: hsl(164.35, 65.71%, 6.86%);
$darkAccent10: hsl(168.51, 74.6%, 12.35%);
$darkAccent15: hsl(169.17, 80%, 17.65%);
$darkAccent20: hsl(170, 81.36%, 23.14%);
$darkAccent25: hsl(170.5, 82.19%, 28.63%);
$darkAccent30: hsl(170.77, 82.28%, 30.98%);
$darkAccent35: hsl(171, 82.35%, 33.33%);
$darkAccent40: hsl(170.53, 83.52%, 35.69%);
$darkAccent45: hsl(170.74, 83.51%, 38.04%);
$darkAccent50: hsl(170.93, 83.5%, 40.39%);
$darkAccent50rgb: 17, 189, 163;
$darkAccent55: hsl(170.68, 59.68%, 48.63%);
$darkAccent60: hsl(170.73, 56.16%, 57.06%);
$darkAccent65: hsl(169.8, 56.82%, 65.49%);
$darkAccent70: hsl(169.6, 56.39%, 73.92%);
$darkAccent75: hsl(168.24, 56.04%, 82.16%);
$darkAccent80: hsl(166.83, 56.16%, 85.69%);
$darkAccent85: hsl(166.45, 56.36%, 89.22%);
$darkAccent90: hsl(164.21, 51.35%, 92.75%);
$darkAccent95: hsl(160, 47.37%, 96.27%);
$darkAccent100: hsl(40, 100%, 99.41%);
$darkAccent100rgb: 255, 254, 252;

@mixin frozenGlassEffect {
  backdrop-filter: blur(6px) saturate(180%);
  -webkit-backdrop-filter: blur(6px) saturate(180%);
}

// Fonts
$b2: 1.125rem;
$a1: .9375rem;
$b1: .8125rem;
$a0: .75rem;
$b0: .625rem;

// Sizes
$bottomPanelHeight: 18rem;
$iconSize: 1.25rem;
$iconToolSize_medium: 2rem;

$mapPadding: .8rem;

// Colors
$backgroundColor_light: var(--base05);
$backgroundColor_lightAccent: rgba(var(--accent50rgb), 0.1);
$backgroundColor_lightTransparent: rgba(245,244,243, .95);
$hoverBackgroundColor: var(--base0);

$textColor_primary: var(--base85);
$textColor_secondary: var(--base50);
$textColor_accented: var(--accent50);
$textColor_disabled: var(--base30);

$iconColor_primary: var(--base80);
$iconColor_secondary: var(--base45);
$iconColor_disabled: var(--base25);
$iconColor_accented: var(--accent45);
$iconColor_inverted: var(--accent0);

$borderColor_light: var(--base25);

// Shadows
$floatingElementShadow: 0 2px 5px rgba(0, 0, 0, 0.35);
$floatingBoxShadow: 0 5px 10px rgba(0, 0, 0, 0.15);

// Animations
$elementOpeningTransitionDuration: 400ms;
$elementOpeningTransitionStyle: ease;

:export {
  a1: $a1;
  b1: $b1;
  b2: $b2;
  bottomPanelHeight: $bottomPanelHeight;

  textColorPrimary: $textColor_primary;
  textColorSecondary: $textColor_secondary;
  textColorAccented: $textColor_accented;

  iconColorPrimary: $iconColor_primary;
  iconColorSecondary: $iconColor_secondary;
  iconColorAccented: $iconColor_accented;
  iconColorDisabled: $iconColor_disabled;
}