@use 'sass:math';
@import '../../../styles/variables';

.ptr-BackgroundLayersControl > .ptr-simple-layers-control {
	position: relative;
	bottom: auto !important;
	right: auto !important;
	margin-bottom: 0;
}

.ptr-BackgroundLayersControl .ptr-simple-layers-control {
	width: $iconToolSize_medium;
	height: $iconToolSize_medium;
	border-radius: math.div($iconToolSize_medium, 2);
	display: flex;
	align-items: center;
	justify-content: center;
	background: $backgroundColor_lightTransparent;
	@include frozenGlassEffect();
	box-shadow: $floatingElementShadow;
}

.ptr-dark .ptr-BackgroundLayersControl .ptr-simple-layers-control {
	border: 0.05rem solid var(--base30);
}

.ptr-BackgroundLayersControl .ptr-simple-layers-control-menu {
	filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.15));
	background: $backgroundColor_lightTransparent;
	@include frozenGlassEffect();
}

.ptr-BackgroundLayersControl .ptr-simple-layers-control-menu.left:after {
	border-left-color: $backgroundColor_lightTransparent;
	@include frozenGlassEffect();
}

.ptr-BackgroundLayersControl .ptr-simple-layers-control.control > .ptr-button {
	display: flex;
	align-items: center;
	justify-content: center;
	transition: none;
	border-radius: math.div($iconToolSize_medium, 2);
}

.ptr-BackgroundLayersControl .ptr-simple-layers-control.control > .ptr-button:hover {
	border-radius: math.div($iconToolSize_medium, 2);
	background: $hoverBackgroundColor;
}

.ptr-BackgroundLayersControl .ptr-simple-layers-control .ptr-button > .ptr-icon {
	width: $iconSize;
	height: $iconSize;
	color: $iconColor_primary;
}

.ptr-BackgroundLayersControl .ptr-simple-layers-control .ptr-button:hover > .ptr-icon {
	color: $iconColor_primary;
}

.ptr-BackgroundLayersControl .ptr-simple-layers-control.open .ptr-button,
.ptr-BackgroundLayersControl .ptr-simple-layers-control.open .ptr-button:hover {
	background: $iconColor_accented;
}

.ptr-BackgroundLayersControl .ptr-simple-layers-control.open .ptr-button > .ptr-icon {
	color: $iconColor_inverted;
}
