@use 'sass:math';
@import '../../../../styles/variables';

.visatSdg-MapTooltip-title {
  font-size: $a1;
  font-weight: bold;
}

.visatSdg-MapTooltip-subtitle {
  font-size: $b1;
  color: var(--base40);
}

.visatSdg-MapTooltip-attributeSet {
  font-size: $b1;
  margin-top: .5rem;
  font-weight: bold;
}

.visatSdg-MapTooltip-attributeSet > span {
  font-weight: normal;
  color: var(--base40);
}

.visatSdg-MapTooltip-attributeSetValues {
  display: flex;
  grid-gap: .5rem;
}

.visatSdg-MapTooltip-year {
  font-size: $a1;
}

.visatSdg-MapTooltip-attributeSetValues .visatSdg-Attribute-absolute > div,
.visatSdg-MapTooltip-attributeSetValues .visatSdg-Attribute-relative > div {
  font-size: $a1;
  color: var(--base20);
}

.visatSdg-MapTooltip-attributeSetValues .visatSdg-Attribute-absolute > span,
.visatSdg-MapTooltip-attributeSetValues .visatSdg-Attribute-relative  > span {
  color: var(--base40);
}