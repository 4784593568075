@use 'sass:math';
@import '../../../../styles/variables';

.visatSdg-Label {
  background: $backgroundColor_lightTransparent;
  @include frozenGlassEffect();
  box-shadow: $floatingBoxShadow;
  min-width: 10rem;
  border-radius: .15rem;
}

.visatSdg-Label-top {
  height: 2.5rem;
  padding: .15rem .65rem .15rem .5rem;
  cursor: pointer;
  transition: background .1s ease-in-out;
  display: flex;
  grid-gap: .35rem;
  align-items: center;
  color: $iconColor_primary;
  z-index: 1;
  position: relative;
}

.visatSdg-Label-top:hover {
  background: var(--base0);
  border-radius: .15rem;
}

.visatSdg-Label > .ptr-icon,
.visatSdg-Label > .ptr-react-icon {
  width: 1rem;
  height: 1rem;
}

.visatSdg-Label > .ptr-icon .line {
  fill: $iconColor_primary;
}

.visatSdg-Label-header {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.visatSdg-Label-title {
  font-size: $b1;
  font-weight: bold;
  color: $textColor_primary;
  line-height: 1.35;
  padding-top: .15rem;
}

.visatSdg-Label-subtitle {
  font-size: $a0;
  color: $textColor_secondary;
  line-height: 1;
}

.visatSdg-Label-rightIcon {
  margin-right: -.25rem;
}

// body
.visatSdg-Label-body {
  height: 0;
  overflow: hidden;
}

.visatSdg-Label-body.is-open {
  height: auto;
}