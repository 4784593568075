@use 'sass:math';
@import '../../../styles/variables';

.visatSdg-Indicator {
  background: $backgroundColor_lightTransparent;
  padding: .75rem .75rem .5rem .75rem;
  border-radius: .15rem;
  box-shadow: $floatingBoxShadow;
  @include frozenGlassEffect();
}

.visatSdg-Indicator.is-active {
  border-top: .25rem solid var(--accent45);
  padding-top: .5rem;
}

.visatSdg-Indicator-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
  color: $textColor_primary;
}

.visatSdg-Indicator-title {
  padding: 0 .5rem 0 0;
}

.visatSdg-Indicator-name {
  color: $textColor_primary;
  font-size: $a1;
  font-weight: bold;
}

.visatSdg-Indicator-description {
  color: $textColor_secondary;
  font-size: $b1;
  line-height: 1.25;
}

.visatSdg-Indicator-toggle {
	margin: -1.5rem -.5rem -.5rem 0;
}
