@use 'sass:math';
@import '../../../styles/variables';

.ptr-LayerLabelTool {
	display: flex;
	height: 2rem;
	font-size: $b1;
	align-items: center;
	padding: 0 0.5rem;
	color: $textColor_primary;
	border-bottom: 1px solid var(--base30);
	text-decoration: none;
}

.ptr-LayerLabelTool:first-child {
	border-top: 1px solid var(--base30);
}

.ptr-LayerLabelTool.is-hoverable {
	cursor: pointer;
}

.ptr-LayerLabelTool.is-hoverable:hover,
a.ptr-LayerLabelTool.is-hoverable:hover {
	background: var(--base0);
	color: $textColor_primary;
}

.ptr-LayerLabelTool.is-disabled {
	color: var(--base30);
	pointer-events: none;
}

.ptr-LayerLabelTool-title {
	flex: 1;
}

.ptr-LayerLabelTool-control {
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: 2rem;
}
