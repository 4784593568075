@use 'sass:math';
@import '../../../../styles/variables';

.visatSdg-AttributeSets {
  display: grid;
  grid-template: auto auto / auto auto;
  margin-top: .5rem;
  grid-gap: .5rem;
  margin-left: -.25rem;
}

.visatSdg-AttributeSets > :nth-child(1) {
  grid-area: 1 / 1 / 2 / 2
}

.visatSdg-AttributeSets > :nth-child(2) {
  grid-area: 2 / 1 / 3 / 2
}