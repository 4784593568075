@use 'sass:math';
@import '../../../styles/variables';

.visatSdg-SummaryChart {
  height: calc(100% - 1rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 .5rem 0;
  margin-top: 1rem;
}

.visatSdg-SummaryChart-charts {
  width: 100%;
  display: flex;
  grid-gap: 2rem;
  height: 100%;
}

.visatSdg-SummaryChart-feature {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.visatSdg-SummaryChart-feature > h3 {
  margin: 0;
  font-size: $b1;
  text-align: center;
}

.visatSdg-SummaryChart-feature-body {
  display: flex;
  flex: 1;
  max-height: 100%;
  width: 100%;
  grid-gap: 1rem;
  padding: 0 .5rem;
}

.visatSdg-SummaryChart-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.visatSdg-SummaryChart-chart > h4 {
  margin: 0;
  font-size: $b1;
  font-weight: normal;
}

.visatSdg-SummaryChart-chart > div {
  max-height: 10rem;
}

.visatSdg-SummaryChart-legend {
  height: 2rem;
  display: flex;
  grid-gap: 1rem;
}

.visatSdg-SummaryChart-legend {
  height: 2rem;
  display: flex;
  grid-gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

.visatSdg-SummaryChart-legend-item {
  display: flex;
  grid-gap: .35rem;
  align-items: center;
}

.visatSdg-SummaryChart-legend-color {
  width: .8rem;
  height: .8rem;
  border-radius: .5rem;
}

.visatSdg-SummaryChart-legend-item > span {
  display: block;
  padding-top: .15rem;
  font-size: $b1;
  color: $textColor_secondary;
}