@use 'sass:math';
@import '../../../../styles/variables';

.visatSdg-Legend {
  display: flex;
  flex: 1;
  max-width: 15rem;
  flex-direction: column;
  padding: .35rem .5rem .5rem .5rem;
  &.expandable{
    cursor: pointer;
  }
}

.visatSdg-Legend.expandable:hover {
  background: var(--base0);
}

