@use 'sass:math';
@import '../../../styles/variables';

.visatSdg-ShareWindow {
  padding: 0 m(1);
}

.visatSdg-ShareWindow-content {
  padding: 0 m(0.1);
}

.visatSdg-ShareWindow-url {
  width: 28rem;
  div {
    display: flex;
    margin-bottom: 20px;
    input {
      width: 100%;
      height: 36px;
    }
    a {
      white-space: nowrap;
      text-align: center;
      margin: auto 10px;
      cursor: pointer;
      display: block;
    }

  }

  p {
    color: var(--base50);
    font-size: $b1;
  }

}

.visatSdg-ShareWindow-social {
  margin-bottom: 20px;
}

.visatSdg-ShareWindow-social-icons {
  display: flex;
  justify-content: flex-start;
  grid-gap: .25rem;
}