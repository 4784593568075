@use 'sass:math';
@import '../../../../../styles/variables';

.visatSdg-AttributeSet {
  padding: .35rem .35rem 0 .35rem ;
  display: flex;
  align-items: center;
  border-radius: .25rem;
  grid-gap: .5rem;
  transition: background-color .1s ease-in-out;
  position: relative;
  border: 2px solid transparent;
}

.visatSdg-AttributeSet.is-active {
  background: $backgroundColor_lightAccent;
  border: 2px solid $iconColor_accented;
}

.visatSdg-AttributeSet:hover {
  background: $backgroundColor_lightAccent;
  cursor: pointer;
}

.visatSdg-AttributeSet-name {
  font-size: $b1;
  color: $textColor_secondary;
  line-height: 1.15;
}

.visatSdg-AttributeSet-values {
  display: flex;
  align-items: baseline;
  grid-gap: .5rem;
}

.visatSdg-Attribute-absolute {
  display: flex;
  align-items: baseline;
}

.visatSdg-Attribute-absolute > div {
  font-size: $b2;
  font-weight: bold;
  color: $textColor_primary;
  margin-right: .15rem;
}

.visatSdg-Attribute-absolute > span {
  font-size: $b1;
  color: $textColor_secondary;
}

.visatSdg-Attribute-relative {
  display: flex;
  align-items: baseline;
}

.visatSdg-Attribute-relative > div {
  font-size: $a1;
  font-weight: bold;
  color: $textColor_accented;
  margin-right: .15rem;
}

.visatSdg-Attribute-relative > span {
  font-size: $b1;
  color: $textColor_accented;
}

.visatSdg-AttributeSet-icon {
  color: $iconColor_inverted;
  position: absolute;
  top: -.35rem;
  right: -.35rem;
}

.visatSdg-AttributeSet-icon .ptr-react-icon {
  display: none;
  width: 1rem;
  height: 1rem;
  border-radius: .75rem;
  background: $iconColor_accented;
  padding: .1rem;
  border: 1px solid var(--base05);
}

.visatSdg-AttributeSet.is-active .visatSdg-AttributeSet-icon .ptr-react-icon {
  display: block;
}

.visatSdg-AttributeSet-loader {
	border: 2px solid #f3f3f3; 
	border-top: 2px solid var(--base50); 
	border-radius: 50%;
	width: 15px;
	height: 15px;
	animation: spin 2s linear infinite;
	margin: 0.4rem 0.2rem;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }