@use 'sass:math';
@import '../../../styles/variables';

$headerHeight: 1.5rem;

.visatSdg-ChartWrapper {
  flex: 1; // TODO each chart type will have different size
  height: 100%;
}

.visatSdg-ChartWrapper:not(:first-child) {
  border-left: 2px dashed var(--base30);
  padding-left: 1rem;
}

.visatSdg-ChartWrapper-header {
  display: flex;
  height: $headerHeight;
}

.visatSdg-ChartWrapper-title {
  margin: 0;
  font-size: $a1;
  font-weight: normal;
  color: $textColor_secondary;
  flex: 1;
  max-width: none;
}

.visatSdg-ChartWrapper-title > em {
  font-weight: bold;
  color: $textColor_primary;
}

.visatSdg-ChartWrapper-content {
  height: calc(100% - 1.5rem);
  overflow: hidden;
}

.visatSdg-ChartWrapper-tools {
  	margin-top: -.3rem;
  	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.visatSdg-ChartWrapper-tools-window {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: absolute;
	overflow: hidden;
	margin: 1.4rem -0.1rem 0 0;
	z-index: 99;
	height: 0;
}

.visatSdg-ChartWrapper-tools-window.is-open {
	height: fit-content;
}

.visatSdg-ChartWrapper-tools-windowConnect {
	width: 1px; 
	height: 1px; 
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 16px solid var(--base10);
	margin-right: 0.2rem;
}

.visatSdg-ChartWrapper-tools-windowContent {
	display: flex;
	flex-direction: column;
	background-color: var(--base10);
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.201);
	margin: 0 0.2rem 0.4rem 0;
}

.visatSdg-ChartWrapper-tools-windowContent-item {
	width: 100%;
	padding: 0.4rem 0.8rem;
	cursor: pointer;
	font-size: $b1;
}

.visatSdg-ChartWrapper-tools-windowContent-item:hover {
	background: $backgroundColor_lightAccent;
}