@use 'sass:math';
@import '../../../../styles/variables';

.ptr-TimeRangeChartLegend {
    display: flex;
    grid-gap: .5rem;
    max-width: calc(100% - 16rem);
}

.ptr-TimeRangeChartLegendItem {
    display: flex;
    align-items: center;
    grid-gap: .25rem;
    overflow: hidden;
}

.ptr-TimeRangeChartLegendItem-icon {
    width: .8rem;
    min-width: .8rem;
    height: .8rem;
    border-radius: .5rem;
}

.ptr-TimeRangeChartLegendItem-label {
    font-size: $b1;
    color: var(--base50);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}