@use 'sass:math';
@import '../../../../../styles/variables';

// Title
.visatSdg-MapLegendTitle {
  line-height: 1;
}

.visatSdg-MapLegendTitle-name {
  font-size: $b1;
  color: $textColor_secondary;
}

.visatSdg-MapLegendTitle-unit {
  font-size: $b1;
  margin-left: .25rem;
  color: $textColor_secondary;
}

// Content
.visatSdg-MapLegendContent {
  display: flex;
  margin-top: .35rem;
  grid-gap: 1rem;
  padding-right: .25rem;
  width: 100%;
  align-items: center;
}

.visatSdg-MapLegendContent.is-expanded {
  flex-direction: column;
  grid-gap: .5rem;
  align-items: flex-start;
}

// Class item
.visatSdg-MapLegendClassItem,
.visatSdg-MapLegendValueItem {
  display: flex;
  grid-gap: .35rem;
}

.visatSdg-MapLegendClassItem > div {
  width: 1.25rem;
  height: .75rem;
}

.visatSdg-MapLegendValueItem > div {
  width: .75rem;
  height: .75rem;
  border-radius: .5rem;
}

.visatSdg-MapLegendClassItem > span,
.visatSdg-MapLegendValueItem > span {
  font-size: $b1;
  line-height: 1;
  color: $textColor_secondary;
}

// Classes Bar
.visatSdg-MapLegendClassesBar {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
}

.visatSdg-MapLegendClassesBar-label {
  font-size: $b1;
  line-height: 1;
  color: $textColor_secondary;
}

.visatSdg-MapLegendClassesBar-bar {
  display: flex;
  flex: 1;
  margin: 0 .25rem;
  height: 100%;
}

.visatSdg-MapLegendClassesBar-bar > div {
  flex: 1;
  height: .75rem;
}

.visatSdg-MapLegendClassesBar .visatSdg-MapLegendClassItem {
  margin-right: .75rem;
}

// Attribute classes
.visatSdg-MapLegend-AttributeClasses {
  display: flex;
  flex: 1;
}

.visatSdg-MapLegend-AttributeClasses.is-expanded {
  flex-direction: column;
  grid-gap: .25rem;
}

// Attribute values
.visatSdg-MapLegend-AttributeValues {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  grid-gap: .35rem;
}

.visatSdg-MapLegend-AttributeValues.is-expanded {
  flex-direction: column;
}

// Attribute scale
.visatSdg-MapLegend-AttributeScale {
  display: flex;
  flex: 1;
}

.visatSdg-MapLegend-AttributeScale.is-expanded {
  width: 100%;
}

.visatSdg-MapLegend-AttributeScale > span {
  font-size: $b1;
  line-height: 1;
  color: $textColor_secondary;
}

.visatSdg-MapLegend-AttributeScale > div {
  flex: 1;
  height: .75rem;
  margin: 0 .25rem;
}

