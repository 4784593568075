@use 'sass:math';
@import '../../../styles/variables';

.visatSdg-Header {
  align-items: center;
  background: $backgroundColor_light;
  border-bottom: 1px solid $borderColor_light;
  display: flex;
  height: 3rem;
  padding: 0 0.5rem 0 1rem;
  position: relative;
  width: 100%;
  z-index: 99;
}

.visatSdg-HeaderLink {
  text-decoration: none;
}

.visatSdg-Header-divider {
  color: $iconColor_secondary;
  font-family: "Roboto" ,"OpenSans", sans-serif;
  margin: .1rem 1rem 0;
  font-size: 1.25rem;
  font-weight: 300;
}

.visatSdg-Logo {
  color: $textColor_accented;
  font-family: "Fredoka" ,"OpenSans", sans-serif;
  font-size: $a2;
  font-weight: 500;
  margin: -.1rem 0 0;
  position: relative;
  line-height: 1.15;
}

.visatSdg-Logo:after {
  content: "";
  height: .2rem;
  width: 0;
  background: var(--accent30);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: width .1s ease-in-out;
}

.visatSdg-Logo:hover::after {
  width: 100%;
}


// Nav
.visatSdg-HeaderNav {
  display: flex;
  align-items: center;
  flex: 1;
}

// Tools
.visatSdg-HeaderTools {

}