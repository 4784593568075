@use 'sass:math';
@import '../../../styles/variables';

$width: 6.2rem;

.ptr-PanelControlButton {
	position: absolute;
	top: 0;
	left: calc(50% - $width/2);
	background: $backgroundColor_lightTransparent;
	@include frozenGlassEffect();
	width: $width;
	border-radius: .5rem .5rem 0 0;
	margin-top: calc(-1.5rem - 1px);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem .25rem 0;
	color: $iconColor_primary;
	border: 1px solid $borderColor_light;
	border-bottom-width: 0;
	z-index: 99;
}

.ptr-PanelControlButton.is-expanded {
	margin-top: 0;
	border-radius: 0 0 .5rem .5rem;
	border-top-width: 0;
	border-bottom-width: 1px;
}

.ptr-PanelControlButton > .ptr-IconTool {
	transition: transform $elementOpeningTransitionDuration $elementOpeningTransitionStyle;
}

.ptr-PanelControlButton > .ptr-IconTool {
	width: 1.5rem;
	height: 1.5rem;
	margin-top: -0.15rem;
}

.ptr-PanelControlButton > span {
	font-size: $b1;
	font-weight: bold;
	padding: 0 4px;
}

.ptr-PanelControlButton-open.is-collapsed {
	transform: rotate(180deg);
}
