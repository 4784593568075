@use 'sass:math';
@import '../../../styles/variables';

.visatSdg-MapLegendStripe {
  width: 2.5rem;
  height: .5rem;
  display: flex;
  margin-left: .3rem;
}

.visatSdg-MapLegendStripe > div {
  flex: 1;
}