@import '../../../styles/variables';

.ptr-OpacitySlider {
	width: 4rem;
	height: .2rem;
	border-radius: 5rem;
	margin-right: 0.25rem;
}

.ptr-OpacitySlider-thumb {
	margin-top: -0.425rem;
	width: 1rem;
	height: 1rem;
	border-radius: 0.5rem;
	background: var(--base10);
	color: var(--accent10);
	border: .15rem solid $iconColor_accented;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: $b1;
	cursor: pointer;
}

.ptr-OpacitySlider-thumb:hover {
	background: var(--accent10);
}

.ptr-OpacitySlider-track {
	top: 0;
	bottom: 0;
	border-radius: 999px;
}

.ptr-OpacitySlider-track-0 {
	background: $iconColor_accented;
}

.ptr-OpacitySlider-track-1 {
	background: var(--base30);
}
